import Axios from 'axios';
import Item from '../../Interfaces/Item';
import { RootState } from '../../types/RootState';
import { Module } from 'vuex';

interface State {
  selectableitems: any[];
  items: Item[];
  orderList: any[];
  remainigItems: number;
  orderListFilter: {
    page: number;
    pageSize: number;
    search: string;
    searchField: string;
    orderBy: string;
    orderByField: string;
    lastPage: number;
    totalCount: number;
    locationId: string;
    startDate: string;
    endDate: string;
  };
  itemFilter: {
    page: number;
    pageSize: number;
    search: string;
    location: string;
    itemType: number;
    topPicks: number;
    outofStock: number;
    sortBy: string;
    sortType: string;
    ids: string[];
  };
  allItemsCreateOrder: Item[];
  selectedItemsById: any[];
  isTransactionInProgress: boolean;
  processedFilteredItems: Item[];
}

const url = `${process.env.VUE_APP_BASE_URL}`;

const createOrder: Module<State, RootState> = {
  state: () => ({
    selectableitems: [],
    items: [],
    orderList: [],
    remainigItems: 0, // IVI-240
    orderListFilter: {
      page: 1,
      pageSize: 15,
      search: '',
      searchField: '',
      orderBy: 'ASC',
      orderByField: '',
      lastPage: 1,
      totalCount: 5,
      locationId: '',
      startDate: '',
      endDate: '',
    },
    itemFilter: {
      page: 1,
      pageSize: 150,
      search: '',
      location: '',
      itemType: 0,
      topPicks: 0,
      outofStock: 0,
      sortBy: '',
      sortType: '',
      ids: [],
    },
    allItemsCreateOrder: [],
    selectedItemsById: [],
    isTransactionInProgress: false,
    processedFilteredItems: [],
  }),
  mutations: {
    setItems: function (state, items) {
      state.items = items;
    },
    // IVI-240: Checking remaining items for get item API on pagination
    setRemainigItems: function (state, remainigItems) {
      state.remainigItems = remainigItems;
    },
    setItemFilter: function (state, filter) {
      state.itemFilter = { ...state.itemFilter, ...filter };
    },
    nextItemPages: function (state) {
      return state.itemFilter.page++;
    },
    setItemSearch: function (state, search) {
      state.itemFilter.search = search;
    },
    setItemLocation: function (state, location) {
      state.itemFilter.location = location;
    },
    setSelectedItemsByIds: function (state, selectedIds) {
      state.selectedItemsById = selectedIds;
    },
    setSelectedItem: function (state, items) {
      state.selectableitems = items;
    },
    setOrderList: function (state, orderList) {
      state.orderList = orderList;
    },
    setOrderListFilter: function (state, filter) {
      state.orderListFilter = { ...state.orderListFilter, ...filter };
    },
    resetItemsFilter: function (state) {
      state.itemFilter = {
        page: 1,
        pageSize: 150,
        search: '',
        location: '',
        itemType: 0,
        topPicks: 0,
        outofStock: 0,
        sortBy: '',
        sortType: '',
        ids: [],
      };
    },
    setTransactionInProgress: function (state, inProgess) {
      state.isTransactionInProgress = inProgess;
    },
    setFilteredItems: (state: State, items) => {
      const copy = JSON.stringify(items);
      state.processedFilteredItems = JSON.parse(copy);
    },
  },
  getters: {
    allItemList: function (state) {
      return state.items;
    },
    selectableitems: function (state) {
      return state.selectableitems;
    },
    itemFilter: function (state) {
      return state.itemFilter;
    },
    getRemainingItems: function (state) {
      return state.remainigItems;
    },
    createOrderPageItems: function (state) {
      return state.allItemsCreateOrder;
    },
    selectedItemsById: function (state) {
      return state.selectedItemsById;
    },
    getOrderList: function (state) {
      return state.orderList;
    },
    getOrderListFilter: function (state) {
      return state.orderListFilter;
    },
    getIsTransactionInProgress: function (state) {
      return state.isTransactionInProgress;
    },
    getProcessedFilteredItems: (state) => {
      return state.processedFilteredItems;
    },
  },
  actions: {
    async getItemList({ commit, state }) {
      if (state.itemFilter.location) {
        const response = await Axios.get(`${url}/item`, {
          params: state.itemFilter,
        });
        if (response.status === 200) {
          if (state.itemFilter.page === 1) {
            state.allItemsCreateOrder = response.data.items;
            state.selectableitems = [];
          } else {
            state.allItemsCreateOrder.concat(response.data.items);
          }
          response.data.items.map((item) => {
            if (item.itemType === 3) {
              let price = 0;
              item.bundles.forEach((bundle) => {
                price += parseFloat(bundle.variant.price.toString());
              });
              if (item.bundles && item.bundles.length > 0) {
                state.selectableitems.push({
                  variantId: '',
                  variantName: '',
                  variantSku: item.bundles ? item.bundles[0].bundleSKU : '',
                  itemHasImage: item.hasImage,
                  itemImageSrc: item.imageSrc,
                  variantPrice: price,
                  variantQuantity: 1,
                  itemDiscount: 0,
                  itemName: item.name,
                  itemType: item.itemType,
                  itemDescription: item.description,
                  itemManufacturer: item.manufacturer,
                  itemTaxes: item.taxes,
                  bundleDiscount:item.bundleDiscount ?? null,
                  bundleCustomPrice: item.bundleCustomPrice  !=="0.00" ? item.bundleCustomPrice : null,
                  bundleDiscountFinal: item.bundleDiscount ? price -((item.bundleDiscount /100) * price): null,
                  itemId: item.id,
                  bundles: item.bundles,
                  bundleOutOfStock: item.bundle_out_of_stock,
                  bundleTotalStock: item.bundle_total_stock,
                  bundleLeastStock: item.bundle_least_stock?.least_stock,
                  bundleLeastStockVarId:
                    item.bundle_least_stock?.least_variant_id,
                  sellingVariant:
                    item.bundles && item.bundles.length > 0
                      ? item.bundles[0].variant.mostPurchasedItem
                      : 0,
                  variantStock: item.bundle_least_stock?.least_stock,
                  variantTracking:
                    item.bundle_least_stock?.least_stock !== null
                      ? true
                      : false,
                  variantUpc: item.bundles ? item.bundles[0].bundleSKU : '',
                  variantLocation:
                    item.bundles && item.bundles.length > 0
                      ? item.bundles[0].variant.variantLocations
                      : [],
                });
              }
            } else {
              let totalDiscountInPercentage = 0;
              let totalDiscountInDoller = 0;

              if (item && item.itemDiscount && item.itemDiscount.length > 0) {
                for (let index = 0; index < item.itemDiscount.length; index++) {
                  if (
                    item.itemDiscount[index].discount.discountType.data[0] === 0
                  ) {
                    if (
                      item.itemDiscount[index].discount.discountFrom &&
                      item.itemDiscount[index].discount.discountTo
                    ) {
                      const discountFromDate = new Date(
                        item.itemDiscount[index].discount.discountFrom,
                      );
                      discountFromDate.setHours(0, 0, 1);
                      const discountToDate = new Date(
                        item.itemDiscount[index].discount.discountTo,
                      );
                      discountToDate.setHours(23, 59, 59);
                      const currentDate = new Date();
                      if (
                        currentDate >= discountFromDate &&
                        currentDate <= discountToDate
                      ) {
                        totalDiscountInPercentage =
                          totalDiscountInPercentage +
                          parseFloat(item.itemDiscount[index].discount.value);
                      }
                    }
                  } else {
                    if (
                      item.itemDiscount[index].discount.discountFrom &&
                      item.itemDiscount[index].discount.discountTo
                    ) {
                      const discountFromDate = new Date(
                        item.itemDiscount[index].discount.discountFrom,
                      );
                      discountFromDate.setHours(0, 0, 1);
                      const discountToDate = new Date(
                        item.itemDiscount[index].discount.discountTo,
                      );
                      discountToDate.setHours(23, 59, 59);
                      const currentDate = new Date();
                      if (
                        currentDate >= discountFromDate &&
                        currentDate <= discountToDate
                      ) {
                        totalDiscountInDoller =
                          totalDiscountInDoller +
                          parseFloat(item.itemDiscount[index].discount.value);
                      }
                    }
                  }
                }
              }
              if (item.variants) {
                item.variants.forEach((variant) => {
                  state.selectableitems.push({
                    variantId: variant.id,
                    variantName: variant.name,
                    variantSku: variant.sku,
                    itemHasImage: item.hasImage,
                    itemImageSrc: item.imageSrc,
                    variantPrice: variant.price,
                    variantQuantity: 1,
                    itemDiscount: 0,
                    itemName: item.name,
                    itemType: item.itemType,
                    itemDescription: item.description,
                    itemManufacturer: item.manufacturer,
                    itemTaxes: item.taxes,
                    itemId: item.id,
                    variantStock:
                      variant.variantLocations.length > 0
                        ? variant.variantLocations[0].currentStock
                        : 0,
                    variantTracking:
                      variant.variantLocations.length > 0
                        ? variant.variantLocations[0].isInventoryTrackingEnabled
                        : false,
                    variantUpc: variant.upc,
                    sellingVariant: variant.mostPurchasedItem,
                    variantLocation: variant.variantLocations,
                    variantDiscountedPrice:
                      variant.price * (totalDiscountInPercentage / 100) +
                      totalDiscountInDoller,
                  });
                });
              }
            }
          });
          // IVI-240: To handle load more option if there is no items remaining
          commit('setItems', response.data.items);
          const remaining =
            response.data.totalCount -
            response.data.filters.page * response.data.filters.pageSize;
          commit('setRemainigItems', remaining);
        }
      }
    },
    async nextItemPage({ commit }) {
      commit('nextItemPages');
    },
    async searchItem({ commit }, search) {
      commit('setItemSearch', search);
    },
    async setItemLocation({ commit }, locationId) {
      commit('setItemLocation', locationId);
    },
    async setFilterItemType({ commit }, itemType) {
      commit('setFilterItemType', itemType);
    },
    async setSelectedItem({ commit }) {
      commit('setSelectedItem', []);
    },
    async getSelectedItems({ commit, state }, ids) {
      // IVI-240: Skipping other filters if there is location ids for bundle
      if (ids) {
        const filter = {
          ids: ids.join(','),
          location: state.itemFilter.location,
        };
        const response = await Axios.get(`${url}/item`, {
          params: filter,
        });
        if (response.status === 200) {
          commit('setSelectedItemsByIds', response.data.items);
          // commit("setItems", response.data.items);
        } else {
          // commit("setItems", []);
          commit('setSelectedItemsByIds', []);
        }
      } else {
        const filter = state.itemFilter;
        filter.page = 1;
        filter.search = '';
        filter.ids = ids.join(',');
        const response = await Axios.get(`${url}/item`, {
          params: filter,
        });
        if (response.status === 200) {
          if (state.itemFilter.page === 1) {
            state.allItemsCreateOrder = response.data.items;
            state.selectableitems = [];
          } else {
            state.allItemsCreateOrder.concat(response.data.items);
          }
          commit('setSelectedItemsByIds', response.data.items);
          // commit("setItems", response.data.items);
        } else {
          // commit("setItems", []);
          commit('setSelectedItemsByIds', []);
        }
      }
    },
    async getOrderList({ commit, state }) {
      const filter = state.orderListFilter;

      if (filter.page !== 0) {
        const response = await Axios.get(`${url}/order`, {
          params: filter,
        });
        if (response.status === 200) {
          filter.lastPage = response.data.lastPage;
          filter.totalCount = response.data.totalCount;
          const orderList = response.data.orders;
          commit('setOrderList', orderList);
        } else {
          commit('setOrderList', []);
        }
      } else {
        commit('setOrderList', []);
      }

      commit('setOrderListFilter', filter);
    },
    async setOrderListFilter({ commit, state }, filter) {
      commit('setOrderListFilter', filter);
    },
  },
};

export default createOrder;
