import Axios from 'axios';
import { createStore } from 'vuex';
import contact from './modules/contact';
import createOrder from './modules/createOrder';
import product from './modules/product';
import quote from './modules/quote';
import tax from './modules/tax';
import transaction from './modules/transaction';
import video from './modules/video';
const url = `${process.env.VUE_APP_BASE_URL}`;
const store = createStore({
  state: () => {
    return {
      baseUrl: url,
      locations: [],
      locationOptions: [],
      futureDiscounts: 0,
      selectedLocationId: null,
      customers: [],
      customerOptions: [{ value: null, text: 'Please select a customer' }],
      selectedCustomerId: null,
      depositeAccounts: [],
      depositeOptions: [{ value: null, text: 'Deposit Account' }],
      selectedDepositeAccouny: null,
      locationDiscounts: [] as Record<string, any>[],
      locationDiscountOptions: [],
      defaultDepositAccount: null,
    };
  },
  getters: {
    allLocationList: function (state) {
      return state.locations;
    },
    locations: function (state) {
      return state.locations;
    },
    allCustomerList: function (state) {
      return state.customers;
    },
    depositeAccounts: function (state) {
      return state.depositeAccounts;
    },
    locationOptions: function (state) {
      return state.locationOptions;
    },
    depositeOptions: function (state) {
      return state.depositeOptions;
    },
    selectedLocationId: function (state) {
      return state.selectedLocationId;
    },
    customerOptions: function (state) {
      return state.customerOptions;
    },
    selectedCustomerId: function (state) {
      return state.selectedCustomerId;
    },

    locationDiscountOptions: function (state) {
      return state.locationDiscountOptions;
    },
    locationDiscounts: function (state) {
      return state.locationDiscounts;
    },
    defaultDepositAccount: function (state) {
      return state.defaultDepositAccount;
    },
  },
  actions: {
    //Marketplace

    ///marketplace
    //TAG: Products

    //TAG: Taxes
    async setLocationsOnStorage({ commit }) {
      const response = await Axios.get(`${url}/location`);
      if (response.status === 200) {
        const locationOptions = [].concat(
          response.data.locations.map(function (location) {
            return { value: location.id, text: location.name };
          }),
        );
        commit('setLocationOptions', locationOptions);
        commit('setLocations', response.data.locations);
        localStorage.setItem(
          'locations',
          JSON.stringify(response.data.locations),
        );
      }
    },

    async getLocationList({ commit }) {
      const locations = localStorage.getItem('locations');
      if (locations) {
        const locs = locations.length ? JSON.parse(locations) : [];
        commit(
          'setLocationOptions',
          locs.map((l) => ({ value: l.id, text: l.name })),
        );
        commit('setLocations', locs);
      }
    },
    async getCustomerList({ commit, state }) {
      const response = await Axios.get(`${url}/customer`, {
        params: {
          locationId: state.selectedLocationId,
        },
      });
      if (response.status === 200) {
        commit('setCustomers', response.data.customer);
        commit(
          'setCustomerOptions',
          [{ value: null, text: 'Please select a customer' }].concat(
            response.data.customer.map(function (customer) {
              return {
                value: customer.id,
                text:
                  customer.first_name +
                  ' ' +
                  customer.last_name +
                  ' - ' +
                  'Acc #:' +
                  customer.account_number,
              };
            }),
          ),
        );
      } else {
        commit('setCustomers', []);
        commit('setCustomerOptions', [
          { value: null, text: 'Please select a customer' },
        ]);
      }
    },
    async getDepositeAccounts({ commit, state }) {
      const response = await Axios.get(`${url}/location`, {
        params: {
          locationId: state.selectedLocationId,
          expand: 'deposite',
        },
      });
      if (response.status === 200) {
        const accounts = [].concat(
          response.data.location.map(function (account) {
            return {
              value: account.id,
              text: account.title,
              paymentMethod: account.payment_method,
            };
          }),
        );
        commit('setDepositeOptions', accounts);
        commit('setDepositeAccounts', response.data.location);
        commit('setDefaultDepositAccount', response.data.default);
      } else {
        commit('setDepositeAccounts', []);
      }
    },

    async setSelectedLocationId({ commit }, locationId) {
      commit('setSelectedLocationId', locationId);
    },
    async setSelectedCustomerId({ commit }, customerId) {
      commit('setSelectedCustomerId', customerId);
    },
    async setLocationOptions({ commit }, locationOptions) {
      commit('setLocationOptions', locationOptions);
    },
    async setCustomerOptions({ commit, state }) {
      commit(
        'setCustomerOptions',
        [{ value: null, text: 'Please select a customer' }].concat(
          state.customers.map(function (customer) {
            return {
              value: customer.id,
              text:
                customer.first_name +
                ' ' +
                customer.last_name +
                ' - ' +
                'Acc #:' +
                customer.account_number,
            };
          }),
        ),
      );
    },
    async setLocationDiscount({ commit, state }) {
      const response = await Axios.get(`${url}/locationDiscount`, {
        params: {
          locationId: state.selectedLocationId,
          futureDiscounts: state.futureDiscounts,
        },
      });
      if (response.status === 200) {
        commit('setLocationDiscount', response.data.locations);
        commit(
          'setLocationDiscountOption',
          response.data.locations.map((discount) => {
            return {
              value: discount.id,
              text:
                discount.name +
                ': ' +
                discount.value +
                (discount.discountType.data[0] === 1 ? '$' : '%'),
            };
          }),
        );
      } else {
        commit('setLocationDiscount', []);
      }
    },
  },
  mutations: {
    setLocations: function (state, locations) {
      return (state.locations = locations);
    },
    setCustomers: function (state, customers) {
      return (state.customers = customers);
    },

    setDepositeAccounts: function (state, depositeAccounts) {
      return (state.depositeAccounts = depositeAccounts);
    },
    setDefaultDepositAccount: function (state, depositAccount) {
      return (state.defaultDepositAccount = depositAccount);
    },
    setLocationOptions: function (state, locationOptions) {
      return (state.locationOptions = locationOptions);
    },
    setDepositeOptions: function (state, depositeOptions) {
      return (state.depositeOptions = depositeOptions);
    },
    setSelectedLocationId: function (state, locationId) {
      return (state.selectedLocationId = locationId);
    },
    setCustomerOptions: function (state, customerOptions) {
      return (state.customerOptions = customerOptions);
    },
    setSelectedCustomerId: function (state, customerId) {
      return (state.selectedCustomerId = customerId);
    },
    setLocationDiscount(state, locationDiscount) {
      return (state.locationDiscounts = locationDiscount);
    },
    setNewLocationDiscount(state, locationDiscount) {
      return (state.locationDiscounts = [
        ...state.locationDiscounts,
        locationDiscount,
      ]);
    },
    setLocationDiscountOption(state, locationDiscountOptions) {
      return (state.locationDiscountOptions = locationDiscountOptions);
    },
  },
  modules: {
    product: product,
    createOrder: createOrder,
    tax: tax,
    video: video,
    quote: quote,
    contact: contact,
    transaction: transaction,
  },
});

export default store;
