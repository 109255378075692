import { RootState } from './../../types/RootState';
import Axios from 'axios';
import { Module } from 'vuex';
import Tax from '../../Interfaces/Tax';

interface State {
  taxes: Tax[];
  taxlist: Tax[];
  taxesByLocation: Tax[];
  taxFilter: {
    page: number;
    pageSize: number;
    search: string;
    searchField: string;
    orderBy: string;
    orderByField: string;
    lastPage: number;
    totalCount: number;
    locationId: null;
    name: string;
    value: string;
  };
}

const url = `${process.env.VUE_APP_BASE_URL}`;

const tax: Module<State, RootState> = {
  state: () => ({
    taxes: [],
    taxlist: [],
    taxesByLocation: [],
    taxFilter: {
      page: 1,
      pageSize: 15,
      search: '',
      searchField: 'name',
      orderBy: 'ASC',
      orderByField: 'name',
      lastPage: 1,
      totalCount: 10,
      locationId: null,
      name: '',
      value: '',
    },
  }),
  mutations: {
    setTaxes: function (state, taxes) {
      state.taxes = taxes;
    },
    setTaxList: function (state, taxlist) {
      state.taxlist = taxlist;
    },
    setTaxFilter: function (state, filter) {
      state.taxFilter = { ...state.taxFilter, ...filter };
    },
    newTaxes: function (state, taxes) {
      state.taxes.unshift(taxes);
    },
    setTaxesByLocation: function (state, taxes) {
      state.taxesByLocation = [...taxes];
    },
    setNewTaxByLocation: function (state, tax) {
      state.taxesByLocation = [...state.taxesByLocation, tax];
    },
  },
  getters: {
    allTaxes: function (state) {
      return state.taxes;
    },
    allTaxeList: function (state) {
      return state.taxlist;
    },
    taxFilter: function (state) {
      return state.taxFilter;
    },
    taxesByLocation: function (state) {
      return state.taxesByLocation;
    },
  },
  actions: {
    async getTaxes({ commit, state }) {
      const filter = state.taxFilter;
      const response = await Axios.get(`${url}/tax`, {
        params: state.taxFilter,
      });
      if (response.status === 200) {
        filter.lastPage = response.data.lastPage;
        filter.totalCount = response.data.totalCount;

        commit('setTaxes', response.data.taxes);
      } else {
        commit('setTaxes', []);
      }
      commit('setTaxFilter', filter);
    },

    async getTaxList({ commit, state }) {
      const response = await Axios.get(`${url}/tax`, {
        params: state.taxFilter,
      });
      if (response.status === 200) {
        commit('setTaxList', response);
      } else {
        commit('setTaxList', []);
      }
    },

    async setTaxFilter({ commit, state }, filter) {
      const newFilter = {
        page: filter.page,
        pageSize: filter.pageSize,
        search: filter.search,
        searchField: filter.searchField,
        orderBy: filter.orderBy,
        orderByField: filter.orderByField,
        lastPage: state.taxFilter.lastPage,
        totalCount: state.taxFilter.totalCount,
        locationId: filter.locationId,
        name: filter.name,
        value: filter.value,
      };
      commit('setTaxFilter', newFilter);
    },
    async addProduct({ commit }, taxes) {
      commit('newTaxes', taxes);
    },
    async getTaxesByLocation({ commit, state }, locationId) {
      const response = await Axios.get(`${url}/tax`, {
        params: {
          ...state.taxFilter,
          page: 0,
          pageSize: 0,
          locationId,
        },
      });
      if (response.status === 200) {
        commit('setTaxesByLocation', response.data.taxes);
      }
    },
  },
};

export default tax;
