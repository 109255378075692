export default class LocalStorageService {
  public store(key: string, item: any) {
    localStorage.setItem(key, item);
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public getItem(key: string) {
    return localStorage.getItem(key);
  }

  clear() {
    return localStorage.clear();
  }
}
