import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';
import GtagService from './services/GtagService';
const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('@/views/Login.vue'),
    redirect: '/login',
  },
  {
    path: '/contacts/:id',
    name: 'show contact',
    component: () => import('@/views/AddContact.vue'),
  },
  {
    path: '/contacts/:id/edit',
    name: 'edit contact',
    component: () => import('@/views/AddContact.vue'),
  },
  {
    path: '/contacts/new',
    name: 'new contact',
    component: () => import('@/views/AddContact.vue'),
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/Contacts.vue'),
  },
  {
    path: '/discounts/:id',
    name: 'discount detail',
    component: () => import('@/views/DiscountDetail.vue'),
  },
  {
    path: '/discounts/new',
    name: 'new discount',
    component: () => import('@/views/DiscountDetail.vue'),
  },
  {
    path: '/discounts',
    name: 'discounts',
    component: () => import('@/views/ListDiscounts.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/items/:id',
    name: 'Show Product',
    component: () => import('@/views/ItemDetailContainer.vue'),
  },
  {
    path: '/items/new',
    name: 'New Product',
    component: () => import('@/views/ItemDetailContainer.vue'),
  },
  {
    path: '/items',
    name: 'items',
    component: () => import('@/views/Items.vue'),
  },
  {
    path: '/orders/:id',
    name: 'order details',
    component: () => import('@/views/OrderDetails.vue'),
  },
  {
    path: '/orders/new',
    name: 'new order',
    component: () => import('@/views/CreateOrder.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/ListOrders.vue'),
  },
  {
    path: '/taxes/:id',
    name: 'show tax',
    component: () => import('@/views/TaxDetail.vue'), 
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/views/Category.vue'),
  },
  {
    path: '/taxes/new',
    name: 'new tax',
    component: () => import('@/views/TaxDetail.vue'),
  },
  {
    path: '/taxes',
    name: 'taxes',
    component: () => import('@/views/ListTaxes.vue'),
  },
  {
    path: '/list-quotes',
    name: 'list-quotes',
    component: () => import('@/views/ListQuote.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/quote/pdf',
    name: 'Quote PDF',
    component: () => import('@/views/Quote.vue'),
    props: true,
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/Reports.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings.vue'),
  },
  {
    path: '/uploadvideo/:id',
    component: () => import('@/views/videoupload.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
  },
  {
    path: '/videolist',
    component: () => import('@/views/videolist.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/PageNotFound.vue'),
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes: routes as RouteRecordRaw[],
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});
router.beforeEach((to, from, next) => {
  console.log('Transitioning to ' + to.fullPath.toString());
  if (to.name) {
    new GtagService().addEvent(to.name.toString());
  }
  if (to.name !== 'login' && to.name !== 'forgotpassword') {
    if (localStorage.getItem('expireIn')) {
      if (Number(Date.now()) > Number(localStorage.getItem('expireIn'))) {
        next({ name: 'root' });
      } else {
        next();
      }
    } else {
      next({ name: 'root' });
    }
  } else {
    next();
  }
});
export default router;
