// @ts-ignore
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/material-dashboard.css';
import './assets/css/custom.css';
import './assets/css/dropdown.css';
// @ts-ignore
import axios from 'axios';
// @ts-ignore
import VueApexCharts from 'vue3-apexcharts';
// import VueApexCharts from "../../dist/vue3-apexcharts.common"
import LocalStorageService from '@/services/LocalStorageService';
// @ts-ignore
import store from './store';
import VueGtag from 'vue-gtag';
import GtagService from './services/GtagService';
import Notification from '@/shared/Notification.vue';
import ConfirmationModal from './shared/ConfirmationModal.vue';
import Pendo from './plugins/pendo';
import PageHeader from '@/shared/PageHeader.vue';
import PageFooter from '@/shared/PageFooter.vue';
import Navbar from '@/shared/Navbar.vue';
import LocationSelector from '@/shared/LocationSelector.vue';
import Spinner from '@/shared/Spinner.vue';

const devtools = window.__VUE_DEVTOOLS_GLOBAL_HOOK__;
if (devtools !== undefined) {
  devtools.enabled = process.env.VUE_APP_ENABLE_DEVTOOLS === 'TRUE';
}

const localStorageService = new LocalStorageService();

axios.interceptors.request.use(
  (request) => {
    if (!process.env.VUE_APP_BASE_URL) {
      process.env.VUE_APP_BASE_URL = '';
    }
    const req_url = request.url;
    if (req_url.match(process.env.VUE_APP_BASE_URL)) {
      request.headers['Authorization'] = `Bearer ${localStorageService.getItem(
        'user-token',
      )}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === 'Unauthorized'
    ) {
      localStorageService.removeItem('locations');
      localStorageService.removeItem('roles');
      localStorageService.removeItem('user-token');
      localStorageService.removeItem('user-name');
      localStorageService.removeItem('auth-token');
      localStorageService.removeItem('userId');
      localStorageService.removeItem('expireIn');
      // window.location.reload()
      window.location.href = window.location.origin;
    }
    return Promise.reject(error);
  },
);
const gtag = new GtagService();
const gtag_id = gtag.getId();
const app = createApp(App);
app.use(
  VueGtag,
  {
    config: { id: gtag_id },
    onBeforeTrack: (e) => {
      //console.warn("tracking started")
    },
    onError: (e) => {
      console.error('error while tracking');
    },
    onAfterTrack: (e) => {
      //console.warn("tracking end")
    },
  },
  router,
);
app.use(store);
app.use(VueApexCharts);
app.component('Notification', Notification);
app.component('ConfirmationModal', ConfirmationModal);
app.component('PageHeader', PageHeader);
app.component('PageFooter', PageFooter);
app.component('Navbar', Navbar);
app.component('LocationSelector', LocationSelector);
app.component('Spinner', Spinner);
app.use(Pendo, {
  isEnabled: process.env.VUE_APP_ENABLE_PENDO === 'TRUE',
});
app.use(router).mount('#app');
