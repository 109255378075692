import Axios from 'axios';
import { Module } from 'vuex';
import { RootState } from '../../types/RootState';
import { Video } from './../../Interfaces/Video';

interface State {
  videolist: Video[];
}

const url = `${process.env.VUE_APP_BASE_URL}`;

const video: Module<State, RootState> = {
  state: () => ({
    videolist: [],
  }),
  mutations: {
    setVideoList: function (state, videolist) {
      state.videolist = videolist;
    },
  },
  getters: {
    allVideoList: function (state) {
      return state.videolist;
    },
  },
  actions: {
    async getVideoList({ commit, state }, flag) {
      const response = await Axios.get(`${url}/getvideo?isActive=${flag}`);
      if (response.status === 200) {
        commit('setVideoList', response.data.items);
      }
    },
  },
};

export default video;
