export function id(id: string) {
  if (!id) {
    console.error('Pendo ID is not defined');
    return false;
  }
  return true;
}

export function isEnabled(isEnabled: boolean) {
  if (typeof isEnabled !== ('boolean' || 'undefined')) {
    console.error(
      `Pendo option isEnabled is of type ${typeof isEnabled} and should be a boolean`,
    );
    return false;
  }
  return true;
}

export function validatePendoOptions(options: { isEnabled: boolean }) {
  return isEnabled(options.isEnabled);
}
