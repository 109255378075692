
import router from '../router';
import LocalStorageService from '@/services/LocalStorageService';
import RoleService from '@/services/RoleService';
import UserService from '@/services/UserService';
import { defineComponent } from 'vue';

interface ComponentData {
  loading: boolean;
  timeoutIdToRefreshToken: number;
  timeoutIdToLogout: number;
  hideRefreshToken: boolean;
  hideLogout: boolean;
  showConfirmModal: boolean;
  localStorageService: LocalStorageService;
  userService: UserService;
  roleService: RoleService;
}

export default defineComponent({
  name: 'PageHeader',
  data() {
    const data: ComponentData = {
      loading: false,
      timeoutIdToRefreshToken: undefined,
      timeoutIdToLogout: undefined,
      hideRefreshToken: false,
      hideLogout: false,
      showConfirmModal: false,
      localStorageService: undefined,
      userService: undefined,
      roleService: undefined,
    };
    return data;
  },
  created() {
    if (window !== window.parent) {
      this.hideLogout = true;
    } else {
      this.hideLogout = false;
    }
    // Show modal after 15 minutes to refresh token
    this.setupTimers(60000 * 14, true);

    this.localStorageService = new LocalStorageService();
    this.userService = new UserService();
    this.roleService = new RoleService(new LocalStorageService());
  },
  methods: {
    logout() {
      this.showConfirmModal = true;
    },

    async confirmLogout() {
      this.localStorageService.removeItem('user-name');
      this.loading = true;
      await this.userService.logout();
      this.localStorageService.removeItem('locations');
      this.localStorageService.removeItem('user-token');
      this.showConfirmModal = false;
      this.hideRefreshToken = false;
      this.loading = false;
      router.push({
        path: `/login`,
      });
    },

    cancelLogout() {
      this.showConfirmModal = false;
    },

    // Show modal to refresh Access Token
    showRefreshTokenModal() {
      this.hideRefreshToken = true;
    },

    startTimer(timeOutInMs: number) {
      this.timeoutIdToRefreshToken = setTimeout(
        this.showRefreshTokenModal,
        timeOutInMs,
      );
    },

    resetTimer(timeOutInMs: number) {
      clearTimeout(this.timeoutIdToRefreshToken);
      clearTimeout(this.timeoutIdToLogout);
      this.startTimer(timeOutInMs);
    },

    setupTimers(timeOutInMs: number) {
      document.addEventListener(
        'keypress',
        void this.resetTimer(timeOutInMs),
        false,
      );
      document.addEventListener(
        'mousemove',
        void this.resetTimer(timeOutInMs),
        false,
      );
      document.addEventListener(
        'mousedown',
        void this.resetTimer(timeOutInMs),
        false,
      );
      document.addEventListener(
        'touchmove',
        void this.resetTimer(timeOutInMs),
        false,
      );
      this.startTimer(timeOutInMs);
    },

    async refreshToken() {
      // clear timeout
      this.loading = true;
      clearTimeout(this.timeoutIdToRefreshToken);
      clearTimeout(this.timeoutIdToLogout);

      // Refresh access token
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      const resp = await this.userService.refreshToken(timestamp);
      this.localStorageService.store(
        'user-token',
        resp.data.refreshToken.token,
      );
      this.hideRefreshToken = false;
      this.loading = false;
    },
  },
  watch: {
    hideRefreshToken(newValue: boolean) {
      if (newValue) {
        // Logout after 45 sec when token is not refresh
        this.timeoutIdToLogout = setTimeout(this.confirmLogout, 45000);
      } else {
        clearTimeout(this.timeoutIdToLogout);
      }
    },
  },
});
