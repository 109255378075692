import Axios from 'axios';
import { isBundleTopPick } from '../../helper/products';
import { RootState } from '../../types/RootState';
import { Module } from 'vuex';
import Item from '@/Interfaces/Item';
import qs from 'qs';

interface State {
  allProducts: Item[];
  productsFilter: {
    searchField: string;
    page: number;
    pageSize: number;
    search: string;
    lastPage: number;
    totalCount: number;
    location: string;
    locationId: string;
    locationIds: string;
    lowStock: number;
    outofStock: number;
    itemType: number;
    sortBy: string;
    sortType: string;
    topPicks: number;
    searchArray: any;
  };
}

const url = `${process.env.VUE_APP_BASE_URL}`;

const product: Module<State, RootState> = {
  state: () => ({
    allProducts: [],
    productsFilter: {
      searchField: '',
      page: 1,
      pageSize: 15,
      search: '',
      lastPage: 1,
      totalCount: 0,
      location: '',
      locationId: '',
      locationIds: '',
      lowStock: 0,
      outofStock: 0,
      itemType: 0,
      sortBy: '',
      sortType: '',
      topPicks: 0,
      searchArray: [],
    },
  }),
  mutations: {
    setProducts: function (state, products) {
      return (state.allProducts = [...products]);
    },
    setProductsFilter: function (state, filter) {
      return (state.productsFilter = { ...state.productsFilter, ...filter });
    },
  },
  getters: {
    allProducts: function (state) {
      return state.allProducts;
    },
    productsFilter: function (state) {
      return state.productsFilter;
    },
  },
  actions: {
    async getProducts({ commit, state }) {
      const { lastPage, totalCount, searchArray, ...rest } =
        state.productsFilter;
      let productsFilter = rest;
      try {
        const response = await Axios.get(`${url}/item`, {
          params: {
            ...rest,
            searchArray: searchArray.length > 0 ? searchArray : null,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        });
        if (response.status === 200) {
          //  state.allProducts.concat(response.data.items);
          productsFilter = {
            ...response.data.filters,
            totalCount: response.data.totalCount,
            itemType: response.data.filters.itemType[0],
          };
          const products = response.data.items.map((product) => {
            const hasVariants = product.variants && product.variants.length > 0;
            const hasVariantLocations =
              hasVariants && product.variants[0].variantLocations && product.variants[0].variantLocations.length > 0;
          
            return {
              ...product,
              isTop:
                hasVariantLocations
                  ? product.variants[0].variantLocations[0].isTop
                  : product.bundles && product.bundles.length > 0
                  ? isBundleTopPick(product.bundles)
                  : false,
            };
          });
          commit('setProducts', products);
          commit('setProductsFilter', productsFilter);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async setProductsFilter({ commit }, filter) {
      commit('setProductsFilter', filter);
    },
  },
};

export default product;
