
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'LocationSelector',
  props: {
    modelValue: {
      default: undefined,
    },
    disable: {
      default: false,
    },
    customClass: {
      default: 'custom-select',
    },
    disableFirstOption: {
      default: true,
    },
    showIcon: {
      default: true,
    },
  },
  methods: {
    ...mapActions(['getLocationList']),
    handleChange($event: any) {
      this.$emit('update:modelValue', $event.target.value);
    },
  },
  computed: {
    ...mapGetters(['locationOptions']),
    isDisabled() {
      return !this.locationOptions.length || this.disable;
    },
  },
  created() {
    this.getLocationList();
  },
});
