import { ItemType } from '@/enums/ItemType';

export const isBundleTopPick = (bundle: Record<string, any>[]) => {
  let isTop = false;
  bundle.forEach((element) => {
    if (element.variant.variantLocations[0].isTop) {
      isTop = true;
    }
  });
  return isTop;
};

export const formatPrice = (value: number) => {
  const format = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const parseValue =  format.format(value).replace(/[$,]/g,'');
  return Number(Number(parseValue).toFixed(2));
};

export const getItemType = (itemType: ItemType): string => {
  switch (itemType) {
    case ItemType.Good:
      return '(Good)';
    case ItemType.Service:
      return '(Service)';
    case ItemType.Bundle:
      return '(Bundle)';
  }
};
