import LocalStorageService from './LocalStorageService';
import axios from 'axios';

export default class RoleService {
  private readonly localStorageService: LocalStorageService;
  constructor(localStorageService: LocalStorageService) {
    this.localStorageService = localStorageService;
    this.getRole();
  }
  roles: string[] = [];

  getRole(): void {
    const storedRoles: string | null =
      this.localStorageService.getItem('roles');
    const parsedRoles: any[] =
      storedRoles !== null ? storedRoles.split(',') : [];
    this.roles = parsedRoles;
  }

  get isAdmin() {
    return this.roles.includes('admin');
  }

  get isStaff() {
    return this.roles.includes('staff');
  }

  get isPatient() {
    return this.roles.includes('patient');
  }

  get isSuperadmin() {
    return this.roles.includes('superadmin');
  }

  get isSupport() {
    return this.roles.includes('support');
  }

  get isSuperadminOrAdmin() {
    return this.isAdmin || this.isSuperadmin;
  }

  get isStaffOrAdmin() {
    return this.isAdmin || this.isStaff;
  }

  get isStaffOrAdminOrSuperadmin() {
    return this.isStaff || this.isAdmin || this.isSuperadmin;
  }

  get isSupportOrStaff() {
    return this.isSupport || this.isStaff;
  }

  public allRoles() {
    return [
      { id: '1', name: 'admin' },
      { id: '2', name: 'staff' },
      { id: '3', name: 'patient' },
      { id: '4', name: 'superadmin' },
      { id: '5', name: 'support' },
      { id: '0', name: 'no role' },
    ];
  }

  public setUserRole(user: any, role: any) {
    return axios.put(
      `${process.env.VUE_APP_BASE_URL}/user/${user}?assignRole=${role.id}`,
    );
  }
}
