import Axios from "axios";
import { Module } from "vuex";
import { RootState } from '../../types/RootState';

interface State {
  transactions: any[];
  transactionFilter: {
    page: number;
    PageSize: number;
    search: string;
    searchField: string;
    orderBy: string;
    orderByField: string;
    lastPage: number;
    totalCount: number;
    locationId: string;
  };
}

let url = `${process.env.VUE_APP_BASE_URL}`;
const transaction: Module<State, RootState> = {
  state: () => ({
    transactions: [],
    transactionFilter: {
      page: 1,
      PageSize: 20,
      search: '',
      searchField: '',
      orderBy: 'ASC',
      orderByField: 'date',
      lastPage: 1,
      totalCount: 5,
      locationId: '',
    },
  }),
  mutations: {
    setTransactions: function (state, transactions) {
      return (state.transactions = transactions);
    },
    setTransactionFIlter: function (state, filter) {
      return (state.transactionFilter = filter);
    },
  },
  getters: {
    transactions: function (state) {
      return state.transactions;
    },
    transactionFilter: function (state) {
      return state.transactionFilter;
    },
  },
  actions: {
    async setTransactionFilter({ commit, rootState }, filter) {
      let newFilter = {
        page: filter.page,
        pageSize: filter.pageSize,
        search: filter.search,
        searchField: filter.searchField,
        orderBy: filter.orderBy,
        orderByField: filter.orderByField,
        lastPage: rootState['tax'].taxFilter.lastPage,
        totalCount: rootState['tax'].taxFilter.totalCount,
      };
      commit('setTransactionFilter', newFilter);
    },
    async getTransactions({ commit, state }) {
      const filter = state.transactionFilter;
      const response = await Axios.get(`${url}/order`, {
        params: state.transactionFilter,
      });
      if (response.status === 200) {
        filter.lastPage = response.data.lastPage;
        filter.totalCount = response.data.totalCount;
        commit('setTrasactions', response.data.orders);
      } else {
        commit('setTransactions', []);
      }
      commit('setTransactionFilter', filter);
    },
  },
};

export default transaction;
