
import { defineComponent } from 'vue';

interface ComponentData {
  show: boolean;
}

export default defineComponent({
  name: 'Notification',
  props: ['activate', 'title', 'message', 'type', 'sided'],
  emits: ['deactivated'],
  data() {
    const data: ComponentData = {
      show: false,
    };
    return data;
  },
  watch: {
    activate(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$emit('deactivated', false);
        }, 3000);
      }
    },
  },
});
