
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmationModal',
  emits: ['confirm', 'cancel', 'dismiss'],
  props: ['title', 'message', 'subMessage', 'positiveButton', 'negativeButton', 'loading', 'deleteAll'],
  computed: {
    formattedMessage() {
      return this.$props.message;
    },
    formatedSubMessage(){
      return this.subMessage;
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm', true);
    },
    cancel() {
      this.$emit('cancel', false);
    },
    dismiss() {
      this.$emit('dismiss', null);
    },
    deleteFromAllLocations() {
      this.$emit('deleteAll', true);
    },
  },
});
