import axios from 'axios';

let gTagId: string;
let pendioId: string;

async function getConfigData() {
  const configResponse = await axios.get(
    `${process.env.VUE_APP_BASE_URL}/fortis/config`,
  );
  gTagId = configResponse.data.id;
  pendioId = configResponse.data.pendioId;
  return {
    gTagId,
    pendioId,
  };
}

const getGTagId = async () => {
  return gTagId ?? (await getConfigData()).gTagId;
};

const getPendioId = async () => {
  return pendioId ?? (await getConfigData()).pendioId;
};

export { getGTagId, getPendioId };
