import Axios from 'axios';
import Customer from '../../Interfaces/Contact';
import { Module } from 'vuex';
import { RootState } from '../../types/RootState';

interface State {
  contactList: Customer[];
  contactFilter: {
    page: number;
    PageSize: number;
    search: string;
    searchField: string;
    finalSearch: object;
    orderBy: string;
    orderByField: string;
    lastPage: number;
    totalCount: number;
    locationId: string | null;
    pageSize: number;
  };
}

const url = `${process.env.VUE_APP_BASE_URL}`;

const contact: Module<State, RootState> = {
  state: () => ({
    contactList: [],
    contactFilter: {
      page: 1,
      PageSize: 150,
      search: '',
      searchField: '',
      finalSearch:[],
      orderBy: 'ASC',
      orderByField: 'first_name',
      lastPage: 1,
      totalCount: 5,
      locationId: null,
      pageSize: 15
    },
  }),
  mutations: {
    setContact: function (state, contactList) {
      state.contactList = contactList;
    },
    setContactFilter: function (state, filter) {
      state.contactFilter = { ...state.contactFilter, ...filter };
    },
    resetContactFilter: function (state) {
      state.contactFilter = {
        page: 1,
        PageSize: 150,
        search: '',
        searchField: '',
        finalSearch:[],
        orderBy: 'ASC',
        orderByField: 'first_name',
        lastPage: 1,
        totalCount: 5,
        locationId: null,
        pageSize: 15,
      };
    },
  },
  getters: {
    allcontactList: function (state) {
      return state.contactList;
    },
    contactFilter: function (state) {
      return state.contactFilter;
    },
  },
  actions: {
    async setContactFilter({ commit, state }, filter) {
      const newFilter = {
        page: filter.page,
        pageSize: filter.pageSize,
        search: filter.search,
        searchField: filter.searchField,
        finalSearch: filter.finalSearch,
        orderBy: filter.orderBy,
        orderByField: filter.orderByField,
        lastPage: state.contactFilter.lastPage,
        totalCount: state.contactFilter.totalCount,
        locationId: filter.locationId,
      };
      commit('setContactFilter', newFilter);
    },
    async getContactList({ commit, state }) {
      const filter = state.contactFilter;

      const encodedFinalSearch = encodeURIComponent(JSON.stringify(filter.finalSearch));

      const response = await Axios.get(`${url}/customer`, {
        params: {
          page: filter.page,
          pageSize: filter.pageSize,
          search: filter.search,
          searchField: filter.searchField,
          finalSearch: encodedFinalSearch, 
          orderBy: filter.orderBy,
          orderByField: filter.orderByField,
          lastPage: filter.lastPage,
          totalCount: filter.totalCount,
          locationId: filter.locationId
        },
      });
      if (response.status === 200) {
        filter.lastPage = response.data.lastPage;
        filter.totalCount = response.data.totalCount;
        commit('setContact', response.data.customer);
      } else {
        commit('setContact', []);
      }
      commit('setContactFilter', filter);
    },
  },
};

export default contact;
