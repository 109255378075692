import axios, { AxiosResponse } from 'axios';
import Audit from '@/Interfaces/Audit';

export default class UserService {
  BASE_URL = `${process.env.VUE_APP_BASE_URL}/user`;

  public async getUsersList(params: any) {
    return await axios.get(`${process.env.VUE_APP_BASE_URL}/user`, {
      params,
    });
  }

  public async setReceiveEmails(userId: string, receiveEmails: boolean) {
    return axios.put(
      `${process.env.VUE_APP_BASE_URL}/user/${userId}?receiveEmails=${receiveEmails}`,
    );
  }

  async getAuditTrailForUser(): Promise<Audit[]> {
    const res = await axios.get(`${this.BASE_URL}?expand=audit`);
    if (res.status === 200) return res.data.audit;
    else return [];
  }

  async updateFilterMobile(
    userId: string,
    filterMobileValue: boolean,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.put(
      `${this.BASE_URL}/${userId}?filterMobile=${filterMobileValue}&locationId=${locationId}`,
    );
  }

  async updateNightlyEmail(
    userId: string,
    nightlyEmailValue: boolean,
    nightlyEmailTimeValue: any,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.put(
      `${process.env.VUE_APP_BASE_URL}/user-setting?nightlyEmail=${nightlyEmailValue}&nightlyEmailTime=${nightlyEmailTimeValue}&locationId=${locationId}`,
    );
  }

  async updateMonthlyEmail(
    userId: string,
    monthlyEmailValue: boolean,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.put(
      `${process.env.VUE_APP_BASE_URL}/user-setting?monthlyEmail=${monthlyEmailValue}&locationId=${locationId}`,
    );
  }

  async updateEveryPurchaseEmail(
    userId: string,
    everyPurchaseEmailValue: boolean,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.put(
      `${process.env.VUE_APP_BASE_URL}/user-setting?everyPurchaseEmail=${everyPurchaseEmailValue}&locationId=${locationId}`,
    );
  }

  async updateCustomerPurchaseEmail(
    userId: string,
    customerPurchaseEmailValue: boolean,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.put(
      `${process.env.VUE_APP_BASE_URL}/user-setting?customerPurchaseEmail=${customerPurchaseEmailValue}&locationId=${locationId}`,
    );
  }
  async updateLowStockAlerts(
    userId: string,
    isCheckedLowStockAlerts: number,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.put(
      `${process.env.VUE_APP_BASE_URL}/user-setting?lowStockAlerts=${isCheckedLowStockAlerts}&locationId=${locationId}`,
    );
  }
  async updateDNPforAllTransactions(
    userId: string,
    isCheckedPostPaymentbacktoEHR: boolean,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.put(
      `${this.BASE_URL}/${userId}?dnp=${isCheckedPostPaymentbacktoEHR}&locationId=${locationId}`,
    );
  }

  async updateReturnPolicy(
    userId: string,
    returnPolicy: string,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.put(
      `${this.BASE_URL}/${userId}?returnPolicy=${returnPolicy}&locationId=${locationId}`,
    );
  }

  async getUserDetails(userId: string): Promise<AxiosResponse> {
    return axios.get(`${this.BASE_URL}/${userId}`);
  }

  async updateUserAccessSettings(
    userId: string,
    request: any,
  ): Promise<AxiosResponse> {
    return axios.put(`${this.BASE_URL}/${userId}?userSetting=1`, request);
  }

  async updateACH(userId: string, ach: any): Promise<AxiosResponse> {
    return axios.put(`${this.BASE_URL}/${userId}?ach=true`, ach);
  }

  async getACH(locationId: string, userId: string): Promise<AxiosResponse> {
    return axios.get(
      `${this.BASE_URL}?ach=true&locationId=${locationId}&userId=${userId}`,
    );
  }

  async updateUserAccessControlDetails(
    userId: string,
    data: any,
  ): Promise<AxiosResponse> {
    return axios.put(`${this.BASE_URL}/${userId}?userSetting=true`, data);
  }

  async acceptTandC(userId: string) {
    return axios.put(`${this.BASE_URL}/${userId}?acceptTandCandPP=true`);
  }

  async refreshToken(timestamp: number) {
    return axios.get(
      `${process.env.VUE_APP_BASE_URL}/authenticate/refresh-token?timestamp=${timestamp}`,
    );
  }

  async logout() {
    return axios.delete(`${process.env.VUE_APP_BASE_URL}/authenticate/logout`);
  }
}
