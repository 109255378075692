
import router from '../router';
import LocalStorageService from '@/services/LocalStorageService';
import UserService from '@/services/UserService';
import { defineComponent } from 'vue';

interface ComponentData {
  loading: boolean;
  showConfirmModal: boolean;
  hideLogout: boolean;
  userService: UserService;
  localStorageService: LocalStorageService;
  userName: string;
}

export default defineComponent({
  name: 'Navbar',
  data() {
    const data: ComponentData = {
      loading: false,
      showConfirmModal: false,
      hideLogout: false,
      userService: undefined,
      localStorageService: undefined,
      userName: undefined,
    };
    return data;
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  created() {
    if (window !== window.parent) {
      this.hideLogout = true;
    } else {
      this.hideLogout = false;
    }
    this.userService = new UserService();
    this.localStorageService = new LocalStorageService();
    this.userName = this.localStorageService.getItem('user-name');
  },
  methods: {
    logout() {
      this.showConfirmModal = true;
    },

    async confirmLogout() {
      this.loading = true;
      this.localStorageService.removeItem('user-name');
      await this.userService.logout();
      this.localStorageService.removeItem('user-token');
      this.localStorageService.removeItem('locations');
      this.showConfirmModal = false;
      this.loading = false;
      router.push({
        path: `/login`,
      });
    },

    cancelLogout() {
      this.showConfirmModal = false;
    },
  },
});
