import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "input-group flex-nowrap" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("select", {
      class: _ctx.customClass,
      value: _ctx.modelValue,
      disabled: _ctx.isDisabled,
      onChange: _cache[1] || (_cache[1] = (...args) => (_ctx.handleChange(...args)))
    }, [
      _createVNode("option", {
        disabled: _ctx.disableFirstOption,
        value: ""
      }, " Please select a location ", 8, ["disabled"]),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.locationOptions, (locationOption) => {
        return (_openBlock(), _createBlock("option", {
          value: locationOption.value,
          key: locationOption.value
        }, _toDisplayString(locationOption.text), 9, ["value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ], 42, ["value", "disabled"])
  ]))
}