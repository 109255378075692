import { event } from 'vue-gtag';
import { getGTagId } from './ConfigService';

export default class GtagService {
  public async addEvent(eventname: string) {
    await event(eventname, { method: 'Google' });
  }

  public async getId() {
    try {
      const configResponse = await getGTagId();
      return configResponse;
    } catch (error) {
      return null;
    }
  }
}
