import { Pendo } from './pendo';

import { validatePendoOptions } from './validations';

const VuePendo = {
  install(app, options) {
    const allOptions = {
      isEnabled: true,
      ...options,
    };
    const { isEnabled } = allOptions;
    if (validatePendoOptions({ isEnabled })) {
      app.config.globalProperties.$pendo = new Pendo(isEnabled);
    }
  },
};

export default VuePendo;
