import Axios from 'axios';
import { Module } from 'vuex';
import { RootState } from '../../types/RootState';
import { Quote } from '@/Interfaces/Quote';

interface State {
  quotesList: Quote[];
  quoteFilter: {
    page: number;
    PageSize: number;
    search: string;
    searchField: string;
    orderBy: string;
    orderByField: string;
    locationId: string;
    firstName: string;
    lastName: string;
    lastPage: number;
    totalCount: number;
  };
}

const url = `${process.env.VUE_APP_BASE_URL}`;

const quote: Module<State, RootState> = {
  state: () => ({
    quotesList: [],
    quoteFilter: {
      page: 1,
      PageSize: 20,
      search: '',
      searchField: '',
      orderBy: 'ASC',
      orderByField: 'quote_name',
      locationId: '',
      firstName: '',
      lastName: '',
      lastPage: 1,
      totalCount: 0,
    },
  }),
  mutations: {
    setQuotes: function (state, quotesList) {
      state.quotesList = quotesList;
    },
    setQuotesFilter: function (state, filter) {
      state.quoteFilter = { ...state.quoteFilter, ...filter };
    },
    resetQuotesFilter: function (state) {
      state.quoteFilter = {
        page: 1,
        PageSize: 20,
        search: '',
        searchField: '',
        orderBy: 'ASC',
        orderByField: 'quote_name',
        locationId: '',
        firstName: '',
        lastName: '',
        lastPage: 1,
        totalCount: 0,
      };
    },
  },
  getters: {
    allQuotes_List: function (state) {
      return state.quotesList;
    },
    quoteFilter: function (state) {
      return state.quoteFilter;
    },
  },
  actions: {
    async setQuotesFilter({ commit, state }, filter) {
      const newFilter = {
        page: filter.page,
        pageSize: filter.pageSize,
        search: filter.search,
        searchField: filter.searchField,
        orderBy: filter.orderBy,
        orderByField: filter.orderByField,
        lastPage: state.quoteFilter.lastPage,
        totalCount: state.quoteFilter.totalCount,
        locationId: filter.locationId,
        lastName: filter.lastName,
        firstName: filter.firstName,
      };
      commit('setQuotesFilter', newFilter);
    },
    async getQuotesList({ commit, state }) {
      const filter = state.quoteFilter;
      if(filter.search){
        filter.page = null
      }
      const response = await Axios.get(`${url}/quote`, {
        params: state.quoteFilter,
      });
      if (response.status === 200) {
        filter.lastPage = response.data.lastPage;
        filter.totalCount = response.data.totalCount;
        const quotes = await response.data.quotes.map((quote) => {
          let customerFirstName = quote.customer?.first_name ?? '';
          let customerLastName = quote.customer?.last_name ?? '';
          return {
            locationId: quote.location.id,
            id: quote.id,
            name: quote.quote_name,
            customerFirstName,
            customerLastName,
            location: quote.location.name,
            total: quote.total,
            timestamp: quote.createdAt,
            createdBy: quote.createdBy.username,
            action: quote.id,
            totalCount: response.data.totalCount
          };
        });
        commit('setQuotes', quotes);
      } else {
        commit('setQuotes', []);
      }
      commit('setQuotesFilter', filter);
    },
  },
};

export default quote;
