import { getPendioId } from '@/services/ConfigService';
import { PendoInitValues } from './pendo-types';

export class Pendo {
  private pendoId: string;
  private isEnabled: boolean;
  constructor(isEnabled: boolean) {
    this.isEnabled = isEnabled;
    if (isEnabled) {
      console.log('Loading Pendo');
      this.setUp();
    } else {
      console.log('Pendo is not enabled');
    }
  }

  private async setUp() {
    try {
      this.pendoId = await getPendioId();
    } catch (error) {
      this.isEnabled = false;
      console.log("Couldn't set up Pendo script");
      throw error;
    }

    (function (apiKey) {
      // eslint-disable-next-line no-var
      (function (p, e, n, d, o) {
        // eslint-disable-next-line no-var
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            // eslint-disable-next-line prefer-rest-params
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  // eslint-disable-next-line prefer-rest-params
                  [m].concat([].slice.call(arguments, 0)),
                );
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(this.pendoId);
  }

  initialize(options: PendoInitValues) {
    if (!this.isEnabled) {
      return;
    }
    console.log('Initializing Pendo');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).pendo.initialize(options);
  }
}
